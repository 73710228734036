import React, { useState, useEffect, useContext } from 'react';

import { useNavigate, Link } from "react-router-dom";
import { useWindowDimensions, useScrollPosition } from './Function';
import { useLocation } from 'react-router-dom'

import mysticflower from './assets/mysticflower.png'

import { UserContext } from "./context/userContext"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { createPortal } from 'react-dom';



export const navData = [
 
  {
    name: "Points de vente",
    link: "/pointsdevente"
  },
  {
    name: "Variétés",
    link: "/varietes"
  },

  {
    name: "Qu'est-ce que le CBD",
    link: "/qu'est-ce-que-le-cbd"
  },
  {
    name: "Devenir partenaire",
    link: "/partenaire"
  },

]

export const menuData = [
  ...navData,
  {
    name: "Nous contacter",
    link: "/contact"
  },

]


function TopBar() {
  let navigate = useNavigate();
  let location = useLocation();


  const [enabledMobileBarMode, setEnabledMobileBarMode] = useState(false)


  const { height, width } = useWindowDimensions();
  // const { scrollX, scrollY } = useScrollPosition();

  // const [isMenuOpen, setIsMenuOpen] = useState(false)

  const [currentSelected, setCurrentSelected] = useState([])

  useEffect(() => {

    console.log(location, "loc")
    var selectedBuff = []
    navData.map((value, index) => {
      if ((location.pathname + location.search).includes(value.link)) {
        selectedBuff = index
        document.title = `${value.name} - MysticFlower`
      }


      setCurrentSelected(selectedBuff)
    })
  }, [location])


  const openMenu = () => {

    var menuContainer = document.getElementById("navMobile");
    menuContainer.className = "nav-mobile-opened";

    // var root = document.getElementById("root");
    // root.style.position = "fixed";

    var menuBars = document.getElementById("menuBars");
    menuBars.style.visibility = "hidden";

    var menuClose = document.getElementById("menuClose");
    menuClose.style.visibility = "visible";

  }

  const closeMenu = () => {

    var menuContainer = document.getElementById("navMobile");
    menuContainer.className = "nav-mobile-closed";

    // var root = document.getElementById("root");
    // root.style.position = "relative";

    var menuBars = document.getElementById("menuBars");
    menuBars.style.visibility = "visible";

    var menuClose = document.getElementById("menuClose");
    menuClose.style.visibility = "hidden";

  }





  return (

    // width > 800 ?
    <>




      {width > 800 ?
        <header >

          <div className="nav-header">
      
          <a href="/"><img className='nav-icon' src={mysticflower} /></a>
            <nav className="nav-menu" >

              {navData.map(((element, index) => {
                return (
                  <Link className="nav-button classic-hyperlink" key={index} to={element.link}><span >{element.name}</span></Link>
                )
              }))}

            </nav>

          </div>
        </header>
        :
        <>

          <header >
            <FontAwesomeIcon id='menuBars' className="button-cursor" onClick={() => { openMenu() }} style={{ position: "absolute", top: "50%", right: 50, transform: 'translate(0%, -50%)', color: "white", }} size="2x" icon={["fa", "fa-bars"]} />
            <FontAwesomeIcon id='menuClose' className="button-cursor" onClick={() => { closeMenu() }} style={{ position: "absolute", top: "50%", right: 50, transform: 'translate(0%, -50%)', color: "white", visibility: 'hidden', }} size="2x" icon={["fa", "fa-xmark"]} />
            <a href="/"><img style={{ height: '65%', position: "absolute", top: "50%", left: "50%", transform: 'translate(-50%, -50%)' }} src={mysticflower} /></a>

          </header>
          <nav id='navMobile' className='nav-mobile' >

            {navData.map(((element, index) => {
              return (

                <Link style={{ textDecoration: "none" }} className="nav-mobile-button" key={index} to={element.link}><span onClick={() => { if (width <= 800) closeMenu() }}>{element.name}</span></Link>



              )
            }))}

          </nav>

        </>
      }



    </>




  )
}

export default TopBar