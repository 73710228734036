import React, { useEffect, useState, useRef, useCallback, useContext } from 'react';

import { useNavigate, useLocation } from "react-router-dom";

import { GoogleMap, useLoadScript, Marker, InfoWindow } from "@react-google-maps/api"

import mapStyles from './mapStyles';

import { UserContext } from "./context/userContext";



const libraries = ["places"]
const mapContainerStyle = {
    height: "400px",
    width: "100%",
    borderRadius: 10
}
const center = {
    lat: 46.227638,
    lng: 2.213749,
}
const options = {
    styles: mapStyles
}

function Map(props) {

    const [markers, setMarkers] = useState([])
    const [selected, setSelected] = useState(null)


    const { getPosName, getLocations, locations } = useContext(UserContext);

    let navigate = useNavigate();

    const mapRef = useRef()
    const onMapLoad = useCallback((map) => {
        mapRef.current = map;
    }, [])

    const panTo = useCallback(({ lat, lng }) => {
        mapRef.current.panTo({ lat, lng });
        mapRef.current.setZoom(18);
    }, []);

    async function getLocationsFromContext() {
        await getLocations()
    }

    const selectFromMain = (location) => {
        setSelected(location)
        panTo({ lat: location.lat, lng: location.lng })
    }



    useEffect(() => {
        getLocationsFromContext()

    }, [])

    return (
        <div className="pos-container" >
            <div className="pos-locations-container" >

                {locations.map((location, index) => {

                    return (
                        <div className='pos-location-container' key={index} style={{ marginBottom: index == locations.length - 1 ? 0 : 20}}>
                            <div className='' style={{ display: 'flex', flexDirection: 'column', color: "white", margin: 10,  borderColor: 'white'}}>
                                <span style={{ fontWeight: '500' }}>{getPosName(location.type)}</span>
                                <span >{location.name.modified != "" && `${location.name.modified}, `}{location.address.modified}</span>
                                <span >{location.postalCode.modified != "" && `${location.postalCode.modified}, `}<span style={{ fontWeight: '500' }}>{location.city.modified}</span></span>
                                <a className='hyperlink new-margin' href={`tel:${location.phoneNumber.modified}`}>{location.phoneNumber.modified}</a>
                                <button className='button new-margin' onClick={(e) => { e.preventDefault(); selectFromMain(location) }} style={{ margin: "auto", fontWeight: 'bold' }} >VOIR</button>
                            </div>
                            {index != locations.length - 1 && <div style={{ height: 1, width: '100%', backgroundColor: 'rgb(20,20,20)' }} />}


                        </div>
                    )

                })}
            </div>
            <GoogleMap
                mapContainerStyle={mapContainerStyle}
                zoom={5}
                center={center}
                options={options}
                onLoad={onMapLoad}
            >
                {locations.map((location, index) => (
                    <Marker
                        key={index}
                        position={{ lat: location.lat, lng: location.lng }}
                        icon={{
                            url: "/marker.svg",
                            scaledSize: new window.google.maps.Size(40, 40),

                        }}
                        onClick={() => {
                            setSelected(location);
                            // panTo({ lat: location.lat, lng: location.lng })
                        }}
                    >
                    </Marker>
                ))}
                {selected ? (
                    <InfoWindow
                        position={{ lat: selected.lat, lng: selected.lng }}
                        onCloseClick={() => {
                            setSelected(null)
                        }}>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', color: "black" }}>
                            <span className='new-margin' style={{ fontWeight: '500' }}>{getPosName(selected.type)}</span>
                            <span >{selected.name.modified != "" && `${selected.name.modified}, `}{selected.address.modified}</span>
                            <span >{selected.postalCode.modified != "" && `${selected.postalCode.modified}, `}{selected.city.modified}</span>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <a style={{}} href={`tel:${selected.phoneNumber.modified}`} className='new-margin'>{selected.phoneNumber.modified}</a>
                            </div>



                            {selected.photos[0] && <img className='new-margin' style={{ height: 100, objectFit: "cover", borderRadius: 5 }} src={selected.photos[0]} />}

                            <a className="button new-margin" href={`https://www.google.com/maps?saddr&daddr=(${selected.lat},${selected.lng})`} target="_blank" style={{ width: "60%", textDecoration: 'none', color: 'white', textAlign: 'center' }} >
                                VOIR SUR GOOGLE MAPS
                            </a>

                        </div>
                    </InfoWindow>
                ) : null}
            </GoogleMap>


        </div>


    )
}





function POS() {


    let navigate = useNavigate();


    const [selectedPage, setSelectedPage] = useState("list")


    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY,
        libraries
    })


    const { locations, getPosName, myPOS, posImage, posKey, posLoading, } = useContext(UserContext);

    const seeSetMarkerInfo = useRef(null)

    const form = useRef()




    useEffect(() => {



    }, [])




    return (
        <main>

            <article>
                <section className='new-margin'>
                    <div style={{display:'flex', justifyContent:'column', alignItems:'center'}} >
                         <h1 className="title-text new-margin">Nos <strong className='gradient-text'>POINTS DE VENTE</strong></h1>
                         </div>
                 
                    {isLoaded && (

                        <form ref={form} className='pos-container-container' onSubmit={() => { }} style={{ }}>

                            <Map className='new-margin' />
                        </form>
                    )}
                </section>
            </article>

        </main>

    )
}

export default POS