import React, { useEffect, useState, useRef } from 'react';

import { useNavigate } from "react-router-dom";

import { useWindowDimensions } from './Function';



import cire from './assets/varieties_type/cire.png'
import fleur from './assets/varieties_type/fleur.png'
import huile from './assets/varieties_type/huile.png'
import pollen from './assets/varieties_type/pollen.png'
import resine from './assets/varieties_type/resine.png'
import roche from './assets/varieties_type/roche.png'
import tisane from './assets/varieties_type/tisane.png'

const varieties_type = [
    {
        image: fleur,
        name: "Fleur",
    },
    {
        image: huile,
        name: "Huile"
    },
    {
        image: tisane,
        name: "Tisane"
    },
    {
        image: cire,
        name: "Cire/Wax"
    },
    {
        image: pollen,
        name: "Pollen"
    },
    {
        image: resine,
        name: "Résine"
    },


    {
        image: roche,
        name: "Roche"
    },
]


function WhatIsCBD() {

    let navigate = useNavigate();



    useEffect(() => {

    }, [])


    return (
        <main>

            <article>
                <section className="cbd-title-container new-big-margin">
                   

                    <h1 className="title-text new-margin">C'est quoi le <strong className='gradient-text'>CBD</strong> ?</h1>

                    <p className="cbd-text">
                        Le cannabidiol, aussi appelé CBD, est une des substances actives principales situé dans les fleurs et dans les feuilles du chanvre. Contrairement au "cannabis", <b>le CBD n'est pas un stupéfiant</b> et est totalement légal.
                    </p>


                </section>
                <section className="cbd-title-container">
                    <p className="cbd-title new-margin" >
                        Les produits à base de CBD
                    </p>

                    <div style={{ display: 'flex', flexWrap: 'wrap', marginInline: 20, maxWidth: 1050, width: '100%' }}>
                        {
                            varieties_type.map((element, index) => {

                                return (
                                    <div className="button-cursor new-big-margin" key={index} onClick={() => { navigate("/varietes") }} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: '5%' }}>
                                        <div style={{ display: 'flex', width: 50, height: 50, padding: 20, backgroundColor: '#202020', borderRadius: '100%', alignItems: 'center', justifyContent: 'center' }}>
                                            <img style={{ maxHeight: '100%', maxWidth: '100%', width: 'auto', margin: 'auto', aspectRatio: 1, }} src={element.image}></img>
                                        </div>
                                        <span>{element.name}</span>

                                    </div>
                                )
                            })
                        }
                    </div>

                </section>
                <section className="cbd-title-container new-big-margin">
                    <p className="cbd-title new-margin" >
                        Quels sont les effets ?
                    </p>

                    <p className="cbd-text">
                        Le CBD est connu pour ses effets médicinaux relaxants qui apaisent, diminuent le stress et les douleurs, améliorent le sommeil et facilitent l'endormissement. Le taux réduit en THC fait du CBD une subtance qui ne rend <b>pas accro</b> et qui n'est <b>pas un psychotrope</b>.
                        Chaque produit et chaque variété a ses propres taux de CBD qui varient les effets ressentis.
                    </p>

                </section>
                <section className="cbd-title-container">

                    <p className="cbd-title new-margin" >
                        Est-ce légal de consommer du CBD ?
                    </p>

                    <p className="cbd-text">
                        Oui, c'est tout à fait légal, que ce soit à la production, à la vente et à la consommation, seulement si le taux de THC est inférieur à 0,3% {'(fixé par la loi française)'}. Le CBD, étant considéré comme une matière végétale à infuser, sa vente dans des épiceries de nuit et autres commerces est à 100% légal.
                    </p>

                </section>
            </article>

        </main>





    )
}

export default WhatIsCBD