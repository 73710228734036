import React, { useEffect, useState, useRef, useContext } from 'react';

import { UserContext } from "./context/userContext";

import {  useNavigate } from 'react-router-dom';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


function Franchise() {

    const form = useRef();

    const [loading, setLoading] = useState(false);
    const [sent, setSent] = useState(false);
    const [sentError, setSentError] = useState(false);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [message, setMessage] = useState("");

    const [openedVarieties, setOpenedVarieties] = useState([])

    const { contactFormMessage } = useContext(UserContext);

    let navigate = useNavigate()



    useEffect(() => {



    }, [])

   


    const openVariety = (index) => {
        var openedVarietiesBuf = [...openedVarieties]
        openedVarietiesBuf[index] = !openedVarietiesBuf[index];
        setOpenedVarieties(openedVarietiesBuf)
    }


    const sendEmail = async e => {
        e.preventDefault();
        if (message == "" || name == "" || email == "" || phoneNumber == "") {
            if (name == "") {
                document.getElementsByName("user_name")[0].style.outlineColor = "red";
                document.getElementsByName("user_name")[0].focus();

                document.getElementsByName("user_email")[0].style.outlineColor = "";
                document.getElementsByName("user_phone")[0].style.outlineColor = "";
                document.getElementsByName("message")[0].style.outlineColor = "";
            }
            else if (email == "") {
                document.getElementsByName("user_email")[0].style.outlineColor = "red";
                document.getElementsByName("user_email")[0].focus();

                document.getElementsByName("user_name")[0].style.outlineColor = "";
                document.getElementsByName("user_phone")[0].style.outlineColor = "";
                document.getElementsByName("message")[0].style.outlineColor = "";
            }
            else if (phoneNumber == "") {
                document.getElementsByName("user_phone")[0].style.outlineColor = "red";
                document.getElementsByName("user_phone")[0].focus();

                document.getElementsByName("user_name")[0].style.outlineColor = "";
                document.getElementsByName("user_email")[0].style.outlineColor = "";
                document.getElementsByName("message")[0].style.outlineColor = "";
            }
            else if (message == "") {
                document.getElementsByName("message")[0].style.outlineColor = "red";
                document.getElementsByName("message")[0].focus();

                document.getElementsByName("user_name")[0].style.outlineColor = "";
                document.getElementsByName("user_phone")[0].style.outlineColor = "";
                document.getElementsByName("user_email")[0].style.outlineColor = "";
            }

            return
        }

        setLoading(true)
        const data = await contactFormMessage(name, email, phoneNumber, message)
        setLoading(false)
        console.log(data, "data")
        if (data.succes) {
            setSent(true)
            setName("")
            setEmail("")
            setPhoneNumber("")
            setMessage("")
        }
        else setSentError(true)
    };


    const inputReset = () => {
        setSent(false)
        setSentError(false)
        document.getElementsByName("message")[0].style.outlineColor = "";
    }

    return (
        <main>
          
            <article >
                <section className='new-margin' style={{ display: 'flex', flexDirection: "column", alignItems: 'center' }}>
                    <h1 className="title-text">Devenir <strong className='gradient-text'>partenaire</strong></h1>
                </section>


                <section className="franchise-title-container new-big-margin">
                    <p className="franchise-title new-margin" >
                       J'ai un commerce et je souhaite y placer un distributeur
                    </p>

                    <p className="franchise-text new-margin">Si vous possédez un tabac, une épicerie, un bar, une boite de nuit, un restaurant ou un autre commerce, nous vous proposons d'y déposer un distributeur MysticFlower sans <b>aucun frais</b>.</p>
                    <p  className="franchise-text">Pour en savoir plus, veuillez <span onClick={()=>{navigate('/contact')}} className='hyperlink'>nous contacter</span>.</p> 
                </section>

                
                <section className="franchise-title-container">
                    <p className="franchise-title new-margin" >
                       Je souhaite ouvrir une franchise et placer plusieurs distributeurs dans des commerces de ma région
                    </p>

                    <p className="franchise-text new-margin">
                        Nous vous permettons d'ouvrir votre propre franchise MysticFlower de distributeurs de CBD sans <b>aucun frais d'ouverture</b> et sans <b>aucunes Royalties</b> sur le chiffre d'affaire/bénéfice. La facilité d'ouverture de franchise MysticFlower et la qualité de nos services sont les résultantes de notre vitesse d'expansion.
                    </p>

                    <p className="franchise-subtitle new-margin" >
                       En quelques mots, comment cela fonctionne ?
                    </p>

                    <p className="franchise-text new-margin">
                       Avec vos distributeurs de CBD MysticFlower, vous irez démarcher des épiceries, tabacs, bars... pour y placer un distributeur en dépôt. Aprés dépôt, attendez quelques jours que des clients achètent du CBD dans votre distributeur et que ce dernier se soit vidé, puis récupérez votre argent, donnez une commission au commerçant et remplissez le distributeur de CBD.
                    </p>



                    <p className="franchise-subtitle new-margin" >
                       Les consommables de CBD
                    </p> 
                    <p className="franchise-text new-margin">
                       Notre CBD est de qualité PREMIUM, savoureux et avec effets ressentis, vendu dans des boites/billes hérmétiques spécialisées pour les distributeurs. Nous avons une dizaine de <span onClick={()=>{navigate('/varietes')}} className='hyperlink'>variétés</span> haut de gamme différentes pour distributeur mécanique, vous permettant de changer régulièrement ce que vous vendez. 
                    </p>

                    <p  className="franchise-text new-big-margin">Pour en savoir plus sur la franchise MysticFlower, veuillez <span onClick={()=>{navigate('/contact')}} className='hyperlink'>nous contacter</span>.</p> 
                    <span >Condition spéciale : acheter uniquement des consommables MysticFlower.</span>

                </section>
               
                



            </article>
        </main>

    )
}

export default Franchise