import React, { useEffect, useState, useRef, useCallback, useContext } from 'react';



import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


import cire from './assets/varieties/wax.png'
import fleur from './assets/varieties/og_kush.png'
import huile from './assets/varieties/potion.png'
import pollen from './assets/varieties/pollen.png'
import resine from './assets/varieties/charas.png'
import roche from './assets/varieties/moon_rock.png'
import tisane from './assets/varieties/tea.png'


import og_kush from './assets/varieties/og_kush.png'
import mystic_amnesia from './assets/varieties/amnesia.png'
import white_widow from './assets/varieties/white_widow.png'
import mystic_orangello from './assets/varieties/orangello.png'
import lemon_haze1 from './assets/varieties/lemon1.png'
import small_bud from './assets/varieties/small_bud.png'
import mystic_trim from './assets/varieties/trim.png'
import mango_kush from './assets/varieties/mango.png'
import cherry_punch from './assets/varieties/cherry.png'
import banana_kush from './assets/varieties/banana.png'

import purple_haze from './assets/varieties/purple_haze.png'
import cherry_haze from './assets/varieties/cherry_haze.png'
import mystic_jungle from './assets/varieties/jungle.png'
import mystic_cannatonic from './assets/varieties/cannatonic.png'
import jack_herer from './assets/varieties/jack_herer.png'
import lemon_haze2 from './assets/varieties/lemon2.png'
import mystic_critical from './assets/varieties/critical.png'
import mystic_peach from './assets/varieties/peach.png'
import bubba_kush from './assets/varieties/bubba_kush.png'
import mystic_strawberry from './assets/varieties/strawberry.png'

import mystic_tonus from './assets/varieties/tonus.png'
import mystic_absinthe from './assets/varieties/absinthe.png'
import mystic_tea from './assets/varieties/tea.png'
import mystic_olympus from './assets/varieties/olympus.png'

import crystal_pollen from './assets/varieties/pollen.png'
import morocco from './assets/varieties/morocco.png'

import moon_rock from './assets/varieties/moon_rock.png'
import mystic_pollen from './assets/varieties/pollen.png'
import mystic_wax from './assets/varieties/wax.png'
import mystic_charas from './assets/varieties/charas.png'
import mystic_potion from './assets/varieties/potion.png'


const varieties_type = [
    {
        image: fleur,
        name: "Fleur",
        type: [
            // {
            //     title: "Distributeur automatique",
            //     items: [{
            //         image: mystic_amnesia,
            //         name: "mystic Amnesia - 0,12% THC et 3,3% CBD - Saveurs agrumes, épices"
            //     },
            //     {
            //         image: white_widow,
            //         name: "White Widow - 0,08% THC et 2 à 6% CBD - Saveurs pin, fruits exotiques, citron"
            //     },
            //     {
            //         image: og_kush,
            //         name: "OG Kush - 0,18% THC et 4,2% CBD - Saveurs citron, agrumes"
            //     },
            //     {
            //         image: mystic_orangello,
            //         name: "mystic Orangello - 0,08% THC et 2,16% CBD - Saveurs orange, citron"
            //     },
            //     {
            //         image: lemon_haze1,
            //         name: "Lemon Haze - 0,08% THC et 4 à 9% CBD - Saveur citron"
            //     },
            //     {
            //         image: mango_kush,
            //         name: "Mango Kush - 0,14% THC et 3,3% CBD - Saveurs mangue, ananas, lavande"
            //     },
            //     {
            //         image: cherry_punch,
            //         name: "Cherry Punch - 0,13% THC et 9% CBD - Saveur cerise"
            //     },
            //     {
            //         image: banana_kush,
            //         name: "Banana Kush - 0,17% THC et 4 à 7% CBD - Saveurs banane, citron"
            //     },
            //     {
            //         image: small_bud,
            //         name: "Small Bud - Petites têtes de fleurs - 0,13% THC et 7% CBD - Saveur fraise"
            //     },
            //     {
            //         image: mystic_trim,
            //         name: "mystic Trim - Mélanges de fleurs passées au tamis - 0,14% THC et 3 à 8% CBD - Saveurs florales"
            //     },
            //     ]

            // },
            {
                title: "Boîtes/billes - Distributeur mécanique",
                items: [
                    // {
                    //     image: purple_haze,
                    //     name: "Purple Haze - <0,2% THC et 2 à 6% CBD - Saveurs fruits"
                    // },
                    // {
                    //     image: cherry_haze,
                    //     name: "Cherry Haze - 0,09% THC et 3 à 6% CBD - Saveur cerise"
                    // },
                    {
                        image: mystic_jungle,
                        name: "Mystic Jungle - <0,2% THC et 9% CBD - Saveurs terreuses, acidulées"
                    },
                    {
                        image: mystic_cannatonic,
                        name: "Mystic Cannatonic - 0,17% THC et 4 à 7% CBD - Saveurs pin, épices"
                    },
                    // {
                    //     image: jack_herer,
                    //     name: "Jack Herer - <0,2% THC et 5% CBD - Saveurs agrumes, épices"
                    // },
                    // {
                    //     image: lemon_haze2,
                    //     name: "(Super) Lemon Haze - 0,16% THC et 5% CBD - Saveur citron"
                    // },
                    {
                        image: mystic_critical,
                        name: "Mystic Critical - 0,09% THC et 4 à 8% CBD - Saveurs citrons, pin, florales"
                    },
                    // {
                    //     image: mystic_peach,
                    //     name: "mystic Peach - <0,2% THC et 6% CBD - Saveur pêche"
                    // },
                    // {
                    //     image: bubba_kush,
                    //     name: "Bubba Kush - 0,08% THC et 3 à 9% CBD - Saveurs agrumes, épices"
                    // },
                    {
                        image: mystic_strawberry,
                        name: "Mystic Strawberry - 0,08% THC et 3 à 5% CBD - Saveur fraise"
                    },

                ]

            }



        ]
    },
    // {
    //     image: huile,
    //     name: "Huile",
    //     type: [
    //         {
    //             title: "Distributeur automatique",
    //             items: [
    //                 {
    //                     image: mystic_potion,
    //                     name: "mystic Potion - 0,18% THC et 5%/10%/20% CBD"
    //                 },
    //             ]
    //         },
    //     ]
    // },


    // {
    //     image: tisane,
    //     name: "Tisane",
    //     type: [
    //         {
    //             title: "Distributeur automatique",
    //             items: [
    //                 {
    //                     image: mystic_tea,
    //                     name: "mystic Tea - 0,12% THC et 6% CBD - Thé vert, matcha, jasmin, chanvre"
    //                 },
    //                 {
    //                     image: mystic_absinthe,
    //                     name: "mystic Absinthe - 0,11% THC et 6% CBD - Thym, romarin, origan, échinacée, chanvre"
    //                 },
    //                 {
    //                     image: mystic_olympus,
    //                     name: "mystic Olympus - 0,12% THC et 6% CBD - Camomille, chanvre"
    //                 },
    //                 {
    //                     image: mystic_tonus,
    //                     name: "mystic Tonus - 0,11% THC et 6% CBD - Thé noir, guarana, gingembre, chanvre"
    //                 },

    //             ]
    //         },
    //     ]
    // },
    {
        image: morocco,
        name: "Les spéciaux",
        type: [
            {
                title: "Distributeur automatique",
                items: [
                    {
                        image: crystal_pollen,
                        name: "Crystal Pollen - <0,2% THC et 9% CBD - Saveurs agrumes"
                    },
                    {
                        image: morocco,
                        name: "Mystic Morocco - Résine -  0,06% THC et 4% CBD - Saveurs agrumes, terreuses"
                    },
                    // {
                    //     image: mystic_pollen,
                    //     name: "Mystic Pollen - Pollen - 0,12% THC et 20% CBD - Saveurs agrumes"
                    // },
                    // {
                    //     image: mystic_wax,
                    //     name: "Mystic Wax - Cire/Wax - 0,17% THC et 73% CBD - Saveur fraise"
                    // },
                    // {
                    //     image: moon_rock,
                    //     name: "Moon Rock - Fleur séchée, imbibée d'huile de CBD et enrobée de pollen - 0,17% THC et 49% CBD - Saveurs florales"
                    // },

                ]
            },
            // {
            //     title: "Boîtes/billes - Distributeur mécanique",
            //     items: [

            //         {
            //             image: crystal_pollen,
            //             name: "Crystall Pollen - <0,2% THC et 9% CBD - Saveurs agrumes"
            //         },


            //     ]
            // },
        ]
    },

]






function Varieties() {



    const [openedVarieties, setOpenedVarieties] = useState([])









    useEffect(() => {



    }, [])


    const openVariety = (index) => {
        var openedVarietiesBuf = [...openedVarieties]
        openedVarietiesBuf[index] = !openedVarietiesBuf[index];
        setOpenedVarieties(openedVarietiesBuf)
    }




    return (
        <main>

            <article>
                <section className="cbd-title-container">
                    {/* <img className="home-title-img"  src={mystic_tower}></img> */}
                    <h1 className="title-text new-margin">Nos <strong className='gradient-text'>variétés</strong></h1>

                    <div style={{ display: 'flex', flexDirection: 'column', maxWidth: 800, width: '100%' }}>
                        {
                            varieties_type.map((element, index) => {

                                return (
                                    <div key={index}>
                                        <div className={`varieties-title-container 
                                      
                                            // !openedVarieties[index] && varieties_type.length - 1 != index && "new-margin"
                                       
                                        `} key={index} onClick={() => {
                                                // element.type && openVariety(index)
                                            }}>
                                            <div className='varieties-title-img-container' >
                                                <div className='varieties-img-container'>
                                                    <img style={{ height: '100%', width: '100%', width: 'auto', margin: 'auto', aspectRatio: 1 }} src={element.image}></img>

                                                </div>
                                                <span style={{ marginLeft: 25, fontWeight: 'bold', }}>{element.name}</span>

                                            </div>

                                            {/* {element.type &&
                                                <>
                                                    <FontAwesomeIcon className={openedVarieties[index] == false ? "chevron-rotate-down" : openedVarieties[index] == undefined ? "" : "none"} size="lg" icon={["fa", "fa-chevron-down"]} />
                                                    <FontAwesomeIcon className={openedVarieties[index] ? "chevron-rotate-up" : "none"} size="lg" icon={["fa", "fa-chevron-up"]} />
                                                </>} */}
                                        </div>

                                        {/* {openedVarieties[index] &&  */}
                                        <div className="varieties-type new-margin" >

                                            {element.type?.map((type, index2) => (

                                                <div key={"item" + index2} style={{ display: "flex", flexDirection: 'column' }}>

                                                    {/* <span className='varieties-type-title' >{type.title}</span> */}

                                                    {type.items?.map((item, index3) => {
                                                        return (
                                                            <div key={"item" + index3} className="varieties-variety" style={{ marginTop: 20, marginBottom: index3 == type.items.length - 1 ? 0 : 20 }} >
                                                                <div className='varieties-img-container'>
                                                                    <img style={{ height: '100%', width: '100%', width: 'auto', margin: 'auto', aspectRatio: 1 }} src={item.image}></img>
                                                                </div>
                                                                <span style={{ marginLeft: 25, fontWeight: 'bold', }}>{item.name}</span>
                                                            </div>
                                                        )
                                                    })}

                                                </div>
                                            ))}

                                        </div>
                                        {/* / } */}
                                    </div>
                                )
                            })
                        }
                    </div>
                </section>
                {/* <section className="cbd-title-container">
                <p className="cbd-title new-margin" >
                    Les produits à base de CBD
                </p>

                 <div style={{ display: 'flex', flexWrap: 'wrap', marginInline: 20, maxWidth: 1050, width: '100%' }}>
                    {
                        // varieties_type.map((element, index) => {

                        //     return (
                        //         <div className="button-cursor new-big-margin" key={index} onClick={() => { navigate("/varietes") }} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: '5%' }}>
                        //             <div style={{ display: 'flex', width: 50, height: 50, padding: 20, backgroundColor: '#202020', borderRadius: '100%', alignItems: 'center', justifyContent: 'center' }}>
                        //                 <img style={{ maxHeight: '100%', maxWidth: '100%', width: 'auto', margin: 'auto', aspectRatio: 1, }} src={element.image}></img>
                        //             </div>
                        //             <span>{element.name}</span>

                        //         </div>
                        //     )
                        // })
                    }
                </div> 

            </section> */}
                {/* <section className="cbd-title-container new-big-margin">
                <p className="cbd-title new-margin" >
                    Quels sont les effets ?
                </p>

                <p className="cbd-text">
                    Le CBD est connu pour ses effets médicinaux relaxants qui apaisent, diminuent le stress et les douleurs, améliorent le sommeil et facilitent l'endormissement. Le taux réduit en THC fait du CBD une subtance qui ne rend <b>pas accro</b> et qui n'est <b>pas un psychotrope</b>.
                    Chaque produit et chaque variété a ses propres taux de CBD qui varient les effets ressentis.
                </p>

            </section>
            <section className="cbd-title-container">

                <p className="cbd-title new-margin" >
                    Est-ce légal de consommer du CBD ?
                </p>

                <p className="cbd-text">
                    Oui, c'est tout à fait légal, que ce soit à la production, à la vente et à la consommation, seulement si le taux de THC est inférieur à 0,3% {'(fixé par la loi française)'}.
                </p>

            </section> */}
            </article>

        </main>
        // <main>



        //     <div className='green-shadow new-big-margin' style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#202020', maxWidth: 600, padding: 20, borderRadius: 15, margin: "auto" }}>




        //         <div style={{ display: 'flex', flexDirection: 'column' }}>




        //             <span className='new-margin' style={{ color: 'white', fontSize: '1.2em', fontWeight: '500' }}>Les variétés selon les couleurs</span>

        //             <div className='new-margin' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>

        //                 <img style={{ width: "auto", height: 60, aspectRatio: 1 / 1, }} src={purplehaze} />

        //                 <span style={{ color: "white", marginLeft: 20, fontWeight: 600 }}>PURPLE HAZE - {'<'}0,2% THC ET 2 À 6% CBD - SAVEURS FRUITS</span>

        //             </div>

        //             <div className='new-margin' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>

        //                 <img style={{ width: "auto", height: 60, aspectRatio: 1 / 1, }} src={cherryhaze} />

        //                 <span style={{ color: "white", marginLeft: 20, fontWeight: 600 }}>CHERRY HAZE - 0,09% THC ET 3 À 6% CBD - SAVEUR CERISE</span>

        //             </div>

        //             <div className='new-margin' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>

        //                 <img style={{ width: "auto", height: 60, aspectRatio: 1 / 1, }} src={mysticjungle} />

        //                 <span style={{ color: "white", marginLeft: 20, fontWeight: 600 }}>mystic JUNGLE - {'<'}0,2% THC ET 9% CBD - SAVEURS TERREUSES, ACIDULÉES</span>

        //             </div>



        //             <div className='new-margin' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>

        //                 <img style={{ width: "auto", height: 60, aspectRatio: 1 / 1, }} src={mysticcannatonic} />

        //                 <span style={{ color: "white", marginLeft: 20, fontWeight: 600 }}>mystic CANNATONIC - 0,17% THC ET 4 À 7% CBD - SAVEURS PIN, ÉPICES</span>

        //             </div>

        //             <div className='new-margin' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>

        //                 <img style={{ width: "auto", height: 60, aspectRatio: 1 / 1, }} src={jackherer} />

        //                 <span style={{ color: "white", marginLeft: 20, fontWeight: 600 }}>JACK HERER - {'<'}0,2% THC ET 5% CBD - SAVEURS AGRUMES, ÉPICES</span>

        //             </div>

        //             <div className='new-margin' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>

        //                 <img style={{ width: "auto", height: 60, aspectRatio: 1 / 1, }} src={lemonhaze} />

        //                 <span style={{ color: "white", marginLeft: 20, fontWeight: 600 }}>LEMON HAZE - 0,16% THC ET 5% CBD - SAVEUR CITRON</span>

        //             </div>


        //             <div className='new-margin' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>

        //                 <img style={{ width: "auto", height: 60, aspectRatio: 1 / 1, }} src={mysticcritical} />

        //                 <span style={{ color: "white", marginLeft: 20, fontWeight: 600 }}>mystic CRITICAL - 0,09% THC ET 4 À 8% CBD - SAVEURS CITRON, PIN, FLORALES</span>

        //             </div>

        //             <div className='new-margin' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>

        //                 <img style={{ width: "auto", height: 60, aspectRatio: 1 / 1, }} src={mysticpeach} />

        //                 <span style={{ color: "white", marginLeft: 20, fontWeight: 600 }}>mystic PEACH - {'<'}0,2% THC ET 6% CBD - SAVEUR PÊCHE</span>

        //             </div>

        //             <div className='new-margin' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>

        //                 <img style={{ width: "auto", height: 60, aspectRatio: 1 / 1, }} src={bubbakush} />

        //                 <span style={{ color: "white", marginLeft: 20, fontWeight: 600 }}>BUBBA KUSH - 0,08% THC ET 3 À 9% CBD - SAVEURS AGRUMES, ÉPICES</span>

        //             </div>


        //             <div className='new-margin' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>

        //                 <img style={{ width: "auto", height: 60, aspectRatio: 1 / 1, }} src={mysticstrawsberry} />

        //                 <span style={{ color: "white", marginLeft: 20, fontWeight: 600 }}>mystic STRAWBERRY - 0,08% THC ET 3 À 5% CBD - SAVEUR FRAISE</span>

        //             </div>




        //             <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>

        //                 <img style={{ width: "auto", height: 60, aspectRatio: 1 / 1, }} src={crystalpollen} />

        //                 <span style={{ color: "white", marginLeft: 20, fontWeight: 600 }}>CRYSTAL POLLEN - {'<'}0,2% THC ET 23% CBD - SAVEURS AGRUMES</span>

        //             </div>














        //         </div>




        //     </div>





        // </main>

    )
}

export default Varieties