import './App.css';
import {
  Routes,
  Route,
  Outlet
} from "react-router-dom";

import ScrollToTop from "./ScrollToTop";

import Home from './Home'
import POS from './POS'
import Varieties from './Varieties'
import Franchise from './Franchise'
import TopBar from './TopBar'
import Footer from './Footer';
import WhatIsCBD from './WhatIsCBD';
import Contact from './Contact';




function App() {
  return (

    <ScrollToTop>
      <TopBar />
      <Routes>
        <Route path="/" exact element={<Layout />}>
          {/* <Route index element={<Home />} /> */}
          <Route path="/" element={<POS />} />
     
          <Route path="/pointsdevente" element={<POS />} />
          <Route path="/varietes" element={<Varieties />} />
          <Route path="/partenaire" element={<Franchise />} />
          <Route path="/qu'est-ce-que-le-cbd" element={<WhatIsCBD />} />
          <Route path="/contact" element={<Contact />} />
           
        </Route>
        <Route path="*" element={<><main className="no-sidenav">Cette page n'existe pas</main></>} />
      </Routes>
    </ScrollToTop>

  );
}

function Layout() {
  return (
    <>

      <Outlet />
      <Footer />
    </>
  );
}


export default App;
