import React, { useState, useEffect } from "react"


//HEIGH & WIDTH

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

//SCROLL POSITION


function getScrollPosition() {

    const { pageXOffset: scrollX, pageYOffset: scrollY } = window;
    return {
        scrollX,
        scrollY
    };
};

export function useScrollPosition() {
    const [scrollPosition, setScollPosition] = useState(getScrollPosition());

    useEffect(() => {
        function handleScroll() {
            setScollPosition(getScrollPosition());
        }

        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return scrollPosition;
}
