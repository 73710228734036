import { createContext, useState, useEffect } from "react";

import {
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    onAuthStateChanged,
    signOut,
    getIdToken,
} from "firebase/auth"

import {
    collection,
    onSnapshot,
    where,
    doc,
    getDoc,
    query,
    orderBy
} from "firebase/firestore"


import { auth, db, functions } from "../firebase-config"

import {
    httpsCallable
} from "firebase/functions"



import { useNavigate, useLocation } from "react-router-dom";

import { library } from '@fortawesome/fontawesome-svg-core'

import { faPhone, faEnvelope, faLocationDot, faBars, faXmark, faArrowRight, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'

import { faInstagram} from '@fortawesome/free-brands-svg-icons'

library.add(faXmark, faBars, faLocationDot, faEnvelope, faPhone, faInstagram, faArrowRight, faChevronDown, faChevronUp)


export const UserContext = createContext()

export function UserContextProvider(props) {

    let navigate = useNavigate()

    let location = useLocation()

    const [locations, setLocations] = useState([]);


    const posName = [
        'Tour mécanique Mystic Tower', 'Globe mécanique Mystic Ball', 'Grand globe mécanique Mystic Mega Ball', 'Distributeur automatique Mystic Box'
    ];
    const posKey = [
        'mystic_tower', 'mystic_ball', 'mystic_mega_ball', 'mystic_box'
    ];
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
        anchor.addEventListener('click', function (e) {
            e.preventDefault();
            document.querySelector(this.getAttribute('href')).scrollIntoView({
                behavior: 'smooth'
            });
        });
    });



    useEffect(() => {

            // if(location.pathname != "/varietes") navigate('/pointsdevente')


    }, [])





    const getPosName = (key) => {
        const index = posKey.findIndex(el => el == key)
        if (index == -1) return posName[0]
        else return posName[index]
    }


    const getLocations = async () => {
      
       return await getDoc(doc(db, "pos", "all_pos")).then( (docc) => {

            const locationBuf = docc.data().data;

            
            setLocations(locationBuf)

           

            return locationBuf
            
            }).catch(err => console.log(err))
     
    }
    const contactFormMessage = async (name, mail, phoneNumber, message, ) => {
      
        const contactFormSend = httpsCallable(functions, 'contactFormSend');

      
      return  contactFormSend(
            {
                name: name,
                phoneNumber:phoneNumber,
                message:message,
                mail:mail,


            }
        ).then(result => {
           return  result.data
        })
        

     }






    return (
        <UserContext.Provider value={{ getPosName, getLocations, contactFormMessage, locations }}>
            {props.children}
        </UserContext.Provider>
    )
}