import React, { useEffect, useState, useRef, useContext } from 'react';

import { UserContext } from "./context/userContext";

import { Dots } from "react-activity";
import "react-activity/dist/library.css";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


function Contact() {

    const form = useRef();

    const [loading, setLoading] = useState(false);
    const [sent, setSent] = useState(false);
    const [sentError, setSentError] = useState(false);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [message, setMessage] = useState("");


    const { contactFormMessage } = useContext(UserContext);




    useEffect(() => {



    }, [])

   




    const sendEmail = async e => {
        e.preventDefault();
        if (message == "" || name == "" || email == "" || phoneNumber == "") {
            if (name == "") {
                document.getElementsByName("user_name")[0].style.outlineColor = "red";
                document.getElementsByName("user_name")[0].focus();

                document.getElementsByName("user_email")[0].style.outlineColor = "";
                document.getElementsByName("user_phone")[0].style.outlineColor = "";
                document.getElementsByName("message")[0].style.outlineColor = "";
            }
            else if (email == "") {
                document.getElementsByName("user_email")[0].style.outlineColor = "red";
                document.getElementsByName("user_email")[0].focus();

                document.getElementsByName("user_name")[0].style.outlineColor = "";
                document.getElementsByName("user_phone")[0].style.outlineColor = "";
                document.getElementsByName("message")[0].style.outlineColor = "";
            }
            else if (phoneNumber == "") {
                document.getElementsByName("user_phone")[0].style.outlineColor = "red";
                document.getElementsByName("user_phone")[0].focus();

                document.getElementsByName("user_name")[0].style.outlineColor = "";
                document.getElementsByName("user_email")[0].style.outlineColor = "";
                document.getElementsByName("message")[0].style.outlineColor = "";
            }
            else if (message == "") {
                document.getElementsByName("message")[0].style.outlineColor = "red";
                document.getElementsByName("message")[0].focus();

                document.getElementsByName("user_name")[0].style.outlineColor = "";
                document.getElementsByName("user_phone")[0].style.outlineColor = "";
                document.getElementsByName("user_email")[0].style.outlineColor = "";
            }

            return
        }

        setLoading(true)
        const data = await contactFormMessage(name, email, phoneNumber, message)
        setLoading(false)
        console.log(data, "data")
        if (data.succes) {
            setSent(true)
            setName("")
            setEmail("")
            setPhoneNumber("")
            setMessage("")
        }
        else setSentError(true)
    };


    const inputReset = () => {
        setSent(false)
        setSentError(false)
        document.getElementsByName("message")[0].style.outlineColor = "";
    }

    return (
        <main>
          
            <article >
                <section className='new-margin' style={{ display: 'flex', flexDirection: "column", alignItems: 'center' }}>
                    <h1 className="title-text">Nous <strong className='gradient-text'>contacter</strong></h1>
                </section>


               
               
                <section>
                <div className="franchise-contact-container">
                      
                      
                        <p className='franchise-contact-text new-big-margin'>Contactez-nous au <a className='hyperlink' href="tel:+33780964200">07 80 96 42 00</a> ou par mail à <a className='hyperlink' href="mailto:contact@mysticflower.fr">contact@mysticflower.fr</a> ou à l'aide du formulaire de contact ci-dessous.</p>
                    </div>
                    <form  className='contact-form green-shadow' ref={form} onSubmit={sendEmail}>
                        <div className='contact-form-container'>
                            <div className='contact-form-inputs-container'>
                                <div className='contact-form-input-container'>
                                    <label className='black' style={{ flexWrap: 'wrap' }}>Nom</label>
                                    <input className="contact-form-input" onChange={(e) => { setName(e.currentTarget.value) }} value={name} onClick={() => { inputReset() }} type="text" name="user_name" />
                                </div>
                                <div className='contact-form-input-container'>
                                    <label className='black'>Email</label>
                                    <input className="contact-form-input" onChange={(e) => { setEmail(e.currentTarget.value) }} value={email} onClick={() => { inputReset() }} type="email" name="user_email" />
                                </div>
                                <div className='contact-form-last-input-container'>
                                    <label className='black'>Téléphone</label>
                                    <input className="contact-form-input" onChange={(e) => { setPhoneNumber(e.currentTarget.value) }} value={phoneNumber} onClick={() => { inputReset() }} type="tel" name="user_phone" />
                                </div>
                            </div>
                            <div className='contact-form-textarea-button-container'>
                                <div className="contact-form-textarea-container">
                                    <label className='black'>Message</label>
                                    <textarea className="contact-form-textarea" onChange={(e) => { setMessage(e.currentTarget.value) }} value={message} onClick={() => { inputReset() }} name="message" />
                                </div>
                                <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'space-between' }}>
                                    <button className="contact-form-sendbtn" disabled={loading} type="submit">
                                        {loading === false ? "Envoyer" : <Dots color="#fff" size={12} speed={1} animating={true} />}
                                    </button>
                                </div>
                            </div>
                        </div>
                        {sent ? <span id="messageSent" className='contact-form-messagesent' >Votre message a bien été envoyé !</span> : null}
                        {sentError ? <span id="messageSent" className='contact-form-messageerror' >Une erreur est survenue, veuillez nous envoyer un mail directement</span> : null}
                    </form>
                </section>
              



            </article>
        </main>

    )
}

export default Contact