// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth"
import {getFunctions} from 'firebase/functions'
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_GOOGLE_KEY,
  authDomain: "mysticflow-ad1d2.firebaseapp.com",
  projectId: "mysticflow-ad1d2",
  storageBucket: "mysticflow-ad1d2.appspot.com",
  messagingSenderId: "164497407169",
  appId: "1:164497407169:web:77901043c8958baffca840"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig)
export const auth = getAuth(app)
export const functions = getFunctions(app, "europe-west1")
export const db = getFirestore(app)