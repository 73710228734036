import React, { useState, useEffect } from 'react';

import { useNavigate, Link } from "react-router-dom";
import { menuData } from "./TopBar.js"
// import linkedin from './assets/linkedin.svg';
import mysticflowerrow from './assets/mysticflower row.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


function Footer() {
  let navigate = useNavigate();
  const [year, setYear] = useState(0)

  useEffect(() => {
    setYear(new Date().getFullYear())
  }, [])
  return (
    <footer >
      <article className="footer-menu">

        <section className="footer-section">
          <h1 className="footer-title">
            Menu
          </h1>
          <nav>
            {menuData.map((element, index) => {
              return (
                   <Link className="classic-hyperlink" key={index}  to={element.link}><h2 className="footer-subtitle">{element.name}</h2></Link>
              )
            })}
          </nav>
        </section>
        <section className="footer-section">
          <h1 className="footer-title">
            Coordonnées
          </h1>
          <a className='classic-hyperlink' href="mailto:contact@mysticflower.fr">
            <h2 className="footer-subtitle">
              <FontAwesomeIcon icon={["fa", "fa-envelope"]} /> contact@mysticflower.fr
            </h2>
          </a>
          <h2 className="footer-subtitle">
            <FontAwesomeIcon icon={["fa", "fa-location-dot"]} /> 229 rue Saint-Honoré 75001 Paris
          </h2>
          <a className='classic-hyperlink'  href="tel:+33780964200">
            <h2 className="footer-subtitle">
              <FontAwesomeIcon icon={["fa", "fa-phone"]} /> 07 80 96 42 00 
            </h2>
          </a>


        </section>

        <section className="footer-section">
          <h1 className="footer-title">
            Social
          </h1>

          <a className='classic-hyperlink' target="_blank" href="http://www.instagram.com/mysticflowercbd">
            <h2 className="footer-subtitle">
              <FontAwesomeIcon size="lg" icon={["fa-brands", "fa-instagram"]} /> mysticflowercbd
            </h2>
          </a>




        </section>

      </article>
      <article >
        <section className="footer-bottom">
          <span className="footer-year">
            © {year}
          </span>
          •
          <img className='footer-icon' src={mysticflowerrow} />
        </section>
      </article>
    </footer>

  )
}

export default Footer